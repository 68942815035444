@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img {
    @apply w-full
  }

  .section {
    @apply container mx-auto py-24 px-6 
  }
  .animate-slide:hover {
    animation-play-state: paused;
  }
}